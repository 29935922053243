import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`We have new functions for you and a fast and easy to use interface:`}</h4>
    <ul>
      <li parentName="ul">{`Share the current map view with all your settings.`}</li>
      <li parentName="ul">{`Quick acccess to activity categories. Show multiple categories at the same time.`}</li>
      <li parentName="ul">{`Adjust the transparency of all map overlays and trails.`}</li>
      <li parentName="ul">{`Improved ergonomics for the location and zoom buttons.`}</li>
      <li parentName="ul">{`Adjustable menu for left or right handers. Show or hide zoom buttons and text labels.`}</li>
      <li parentName="ul">{`Click once on the screen to hide all buttons.`}</li>
      <li parentName="ul">{`Icons & text lables for easy understanding.`}</li>
    </ul>
    <Image src="/news/screenshot_new.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`The `}<b>{`lower menu bar`}</b>{` contains new functions. Text labels and icons make
it easier to understand. The location and zoom buttons can be
reached with the thumb. Left handers can choose to have the buttons on the other side.
You can also hide or show the zoom buttons or text labels:`}</p>
    <Image src="/news/settings.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`The `}<b>{`share`}</b>{` function allows you to easily share the current map view with all your
settings in another app. Directly share it in an app or copy the URL.`}</p>
    <Image src="/news/share.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`Now you can see the selected `}<b>{`category`}</b>{` on the main screen. It is possible to select
and show multiply categries at the same time.`}</p>
    <Image src="/news/categories.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`Press, hold and slide your finger to adjust the `}<b>{`transparency`}</b>{` of all activated overlays and trails.
This allows you to see the base map underneath. Is it a singletrack, a gravel road or a paved road?`}</p>
    <Image src="/news/transparency.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`To activate the `}<b>{`full screen`}</b>{` view and hide all buttons click once somewhere on the screen.
Another click brings everything back.`}</p>
    <Image src="/news/one_click.png" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`We hope you like it and it would be great to get feedback through
`}<a parentName="p" {...{
        "href": "https://www.instagram.com/trailguideapp/"
      }}>{`Instagram`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      